import React from 'react';

import type { ClassNameProps } from '@/types/common.types';

const NotFound = ({ className }: ClassNameProps) => {
  return (
    <svg
      width="308"
      height="338"
      viewBox="0 0 308 338"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path
          opacity="0.7"
          d="M201.551 47.4074C201.55 56.835 198.753 66.0505 193.515 73.8887C188.277 81.727 180.832 87.836 172.121 91.4432C163.411 95.0504 153.827 95.9939 144.581 94.1544C135.334 92.3149 126.841 87.775 120.175 81.1087C113.509 74.4424 108.969 65.9491 107.129 56.7027C105.29 47.4564 106.233 37.8722 109.84 29.1621C113.448 20.4519 119.557 13.007 127.395 7.76862C135.233 2.53025 144.449 -0.266301 153.876 -0.267426C160.141 -0.283297 166.348 0.939017 172.139 3.32928C177.931 5.71955 183.193 9.23064 187.623 13.6608C192.053 18.091 195.564 23.3529 197.954 29.1443C200.345 34.9356 201.567 41.1422 201.551 47.4074Z"
          fill="#FFFF4D"
        />
        <path
          d="M197.255 51.6928C197.27 63.3074 193.03 74.5252 185.336 83.2263C176.233 91.2198 164.427 95.4474 152.319 95.05C140.21 94.6526 128.707 89.6601 120.148 81.0869C111.588 72.5138 106.613 61.0032 106.234 48.8942C105.856 36.7852 110.101 24.9863 118.109 15.8951C124.994 9.84281 133.478 5.90404 142.545 4.5507C151.612 3.19737 160.876 4.48686 169.229 8.26469C177.581 12.0425 184.667 18.1485 189.638 25.8509C194.609 33.5534 197.253 42.5257 197.255 51.6928Z"
          fill="#273B4C"
        />
        <path
          opacity="0.7"
          d="M153.88 103.407C234.708 103.407 300.231 88.4898 300.231 70.0887C300.231 51.6876 234.708 36.7705 153.88 36.7705C73.0528 36.7705 7.5293 51.6876 7.5293 70.0887C7.5293 88.4898 73.0528 103.407 153.88 103.407Z"
          fill="#FFFF4D"
        />
        <path
          d="M300.228 72.9064C300.228 90.6596 239.241 105.158 162.362 106.166C159.554 106.198 156.726 106.215 153.876 106.218C151.151 106.218 148.437 106.198 145.753 106.166C71.8282 105.251 12.5052 91.8349 7.82506 75.0489C7.62742 74.3518 7.52594 73.6309 7.52344 72.9064C7.52344 66.7598 14.8348 60.9981 27.596 56.0579C48.3549 48.0082 83.5181 42.1217 125.026 40.2392C126.918 40.1455 128.822 40.0728 130.746 40C138.275 39.7295 146.003 39.584 153.876 39.584C161.874 39.584 169.726 39.7295 177.38 40.0104C179.294 40.0832 181.197 40.1559 183.08 40.2495C249.922 43.3281 300.228 56.786 300.228 72.9064Z"
          fill="#162630"
        />
        <path
          d="M300.228 74.5978C300.228 92.3511 239.241 106.849 162.362 107.858C159.554 107.889 156.726 107.906 153.876 107.91C151.151 107.91 148.437 107.889 145.753 107.858C71.8282 106.943 12.5052 93.5263 7.82506 76.7403C7.62742 76.0432 7.52594 75.3223 7.52344 74.5978C7.52344 68.4513 14.8348 62.6896 27.596 57.7494C48.3549 49.6996 83.5181 43.8131 125.026 41.9306C126.918 41.8371 128.822 41.7642 130.746 41.6914C138.275 41.421 146.003 41.2754 153.876 41.2754C161.874 41.2754 169.726 41.421 177.38 41.7018C179.294 41.7747 181.197 41.8474 183.08 41.941C249.922 45.0195 300.228 58.4774 300.228 74.5978Z"
          fill="#273B4C"
        />
        <path
          d="M283.435 75.962C283.435 78.6615 277.169 80.8499 269.438 80.8499C261.708 80.8499 255.441 78.6615 255.441 75.962C255.441 73.2625 261.708 71.0742 269.438 71.0742C277.169 71.0742 283.435 73.2626 283.435 75.962Z"
          fill="#FAC99E"
        />
        <path
          d="M54.283 75.962C54.283 78.6615 48.0164 80.8499 40.286 80.8499C32.5557 80.8499 26.2891 78.6615 26.2891 75.962C26.2891 73.2625 32.5557 71.0742 40.286 71.0742C48.0164 71.0742 54.283 73.2626 54.283 75.962Z"
          fill="#FAC99E"
        />
        <path
          d="M161.013 103.361C161.013 103.938 160.075 104.457 158.587 104.81C157.04 105.146 155.461 105.306 153.878 105.289C149.938 105.289 146.744 104.426 146.744 103.361C146.744 102.872 147.417 102.426 148.525 102.087C150.27 101.62 152.072 101.4 153.878 101.433C157.819 101.433 161.013 102.296 161.013 103.361Z"
          fill="#FAC99E"
        />
        <path
          d="M170.247 50.7539C170.247 52.0777 168.094 53.2665 164.681 54.0771C161.133 54.8467 157.51 55.2154 153.881 55.176C144.844 55.176 137.518 53.196 137.518 50.7539C137.518 49.6327 139.06 48.6089 141.602 47.8302C145.605 46.7594 149.738 46.2547 153.881 46.3307C162.921 46.3307 170.247 48.3107 170.247 50.7539Z"
          fill="#FAC99E"
        />
        <path
          d="M153.949 73.0582V86.7554C153.949 86.7554 27.8976 91.9971 7.82506 76.7399C7.62742 76.0428 7.52594 75.322 7.52344 74.5974C7.52344 68.451 14.8348 62.6892 27.596 57.749C26.7432 58.3523 23.4047 61.3267 34.6994 64.7381C47.346 68.5549 153.949 73.0582 153.949 73.0582Z"
          fill="#162630"
        />
        <path
          d="M153.805 73.0582V86.7554C153.805 86.7554 279.856 91.9971 299.928 76.7399C300.126 76.0428 300.227 75.322 300.23 74.5974C300.23 68.451 292.918 62.6892 280.157 57.749C281.01 58.3523 284.349 61.3267 273.054 64.7381C260.407 68.5549 153.805 73.0582 153.805 73.0582Z"
          fill="#162630"
        />
        <path
          d="M164.911 89.3442L162.363 107.857C159.555 107.888 156.726 107.905 153.877 107.909C151.152 107.909 148.437 107.888 145.754 107.857L143.206 89.3442C142.956 89.0009 149.914 88.1378 153.211 87.7425V87.5449C153.211 87.5449 153.533 87.5865 154.053 87.6489C154.584 87.5865 154.906 87.5449 154.906 87.5449V87.7425C158.203 88.1378 165.161 89.0009 164.911 89.3442Z"
          fill="#162630"
        />
        <path
          d="M183.08 41.941L177.37 71.9977C169.84 72.2682 162.113 72.4138 154.24 72.4138C146.242 72.4138 138.39 72.2682 130.735 71.9873L125.025 41.9306C126.918 41.8371 128.821 41.7642 130.746 41.6914C138.275 41.421 146.003 41.2754 153.876 41.2754C161.874 41.2754 169.726 41.421 177.38 41.7018C179.294 41.7747 181.197 41.8474 183.08 41.941Z"
          fill="#162630"
        />
        <path
          d="M153.879 97.4528C202.602 97.4528 242.1 89.0816 242.1 78.7552C242.1 68.4288 202.602 60.0576 153.879 60.0576C105.156 60.0576 65.6582 68.4288 65.6582 78.7552C65.6582 89.0816 105.156 97.4528 153.879 97.4528Z"
          fill="#273B4C"
        />
        <path
          opacity="0.7"
          d="M153.878 92.5992C189.953 92.5992 219.198 86.692 219.198 79.4051C219.198 72.1182 189.953 66.2109 153.878 66.2109C117.802 66.2109 88.5566 72.1182 88.5566 79.4051C88.5566 86.692 117.802 92.5992 153.878 92.5992Z"
          fill="#FFFF4D"
        />
        <path
          d="M153.877 87.0182C174.692 87.0182 191.565 83.6099 191.565 79.4056C191.565 75.2012 174.692 71.793 153.877 71.793C133.063 71.793 116.189 75.2012 116.189 79.4056C116.189 83.6099 133.063 87.0182 153.877 87.0182Z"
          fill="#FFFF4D"
        />
        <path
          opacity="0.15"
          d="M265.856 318.192C261.28 313.959 212.804 310.641 153.679 310.641C93.8045 310.641 44.8505 314.052 41.3457 318.358L115.666 79.2979H191.588L265.856 318.192Z"
          fill="#FFFF4D"
        />
        <path
          opacity="0.3"
          d="M266.145 319.128C265.874 319.559 265.468 319.888 264.991 320.064C264.98 320.064 264.98 320.075 264.97 320.075C256.889 324.027 210.129 327.064 153.677 327.064C97.2137 327.064 50.4541 324.027 42.3732 320.075C42.3718 320.075 42.3704 320.074 42.3691 320.074C42.3679 320.073 42.3667 320.073 42.3657 320.072C42.3648 320.071 42.364 320.07 42.3635 320.068C42.363 320.067 42.3627 320.066 42.3628 320.064C42.1214 319.952 41.8915 319.816 41.6763 319.659C41.6139 319.607 41.562 319.565 41.51 319.513C41.3322 319.375 41.2073 319.181 41.1562 318.962L41.3435 318.359C44.8483 314.053 93.8023 310.642 153.677 310.642C212.802 310.642 261.278 313.959 265.854 318.192L266.145 319.128Z"
          fill="#FFFF4D"
        />
        <path
          opacity="0.8"
          d="M112.439 237.676L85.0181 278.575L92.5788 280.076L95.5592 265.066L119.019 269.724L116.039 284.735L124.933 286.501L121.291 304.846L112.396 303.08L109.394 318.201L85.9337 313.543L88.9361 298.421L55.6914 291.82L59.0029 275.143L88.2006 232.863L112.439 237.676Z"
          fill="#FFFF4D"
        />
        <path
          opacity="0.8"
          d="M183.18 246.933C176.517 272.139 161.768 283.132 141.823 277.859C121.658 272.528 114.378 255.712 121.042 230.506C127.705 205.301 142.343 194.28 162.509 199.611C182.453 204.883 189.843 221.727 183.18 246.933ZM145.042 236.851C140.783 252.962 140.863 259.314 146.343 260.763C151.712 262.182 154.921 256.699 159.18 240.589C163.467 224.369 163.358 218.126 157.989 216.707C152.509 215.258 149.33 220.632 145.042 236.851Z"
          fill="#FFFF4D"
        />
        <path
          opacity="0.8"
          d="M224.605 235.939L205.676 281.396H213.383V266.092H237.301V281.396H246.37V300.099H237.301V315.516H213.383V300.099H179.49V283.096L199.895 235.939H224.605Z"
          fill="#FFFF4D"
        />
        <path
          opacity="0.38"
          d="M125.215 320.669C125.215 321.756 113.529 322.637 99.1126 322.637C84.6965 322.637 73.0098 321.756 73.0098 320.669C73.0098 319.582 84.6964 318.7 99.1126 318.7C113.529 318.7 125.215 319.582 125.215 320.669Z"
          fill="#929203"
        />
        <path
          opacity="0.38"
          d="M220.298 321.096C234.714 321.096 246.401 320.215 246.401 319.128C246.401 318.04 234.714 317.159 220.298 317.159C205.882 317.159 194.195 318.04 194.195 319.128C194.195 320.215 205.882 321.096 220.298 321.096Z"
          fill="#929203"
        />
        <path
          opacity="0.38"
          d="M169.547 319.384C169.547 320.2 160.772 320.862 149.948 320.862C139.124 320.862 130.35 320.2 130.35 319.384C130.35 318.568 139.124 317.906 149.948 317.906C160.772 317.906 169.547 318.568 169.547 319.384Z"
          fill="#929203"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="308" height="338" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotFound;
