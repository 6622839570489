'use client';

import { Fragment } from 'react';
import { Container } from 'react-bootstrap';

import { useRouter } from 'next/navigation';

import BackToHomeSVG from '@/icons/BackToHomeSVG';
import NotFoundSVG from '@/icons/NotFoundSVG';

import styles from '@/views/GeneralNotFoundView/GeneralNotFoundView.module.scss';

export const GeneralNotFoundView = () => {
  const router = useRouter();

  return (
    <Fragment>
      <Container>
        <button
          onClick={() => router.replace('/ru')}
          title="Back to Home"
          className={styles.backButton}
        >
          <BackToHomeSVG />
        </button>
      </Container>
      <div className={styles.wrapper}>
        <NotFoundSVG className={styles.image} />
        {/* <Error statusCode={404} /> */}
        <h2>Страница не найдена</h2>
        {/* <Link href={'/ru'}>Home</Link> */}
      </div>
    </Fragment>
  );
};
